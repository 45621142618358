import React from 'react'

class NotFound extends React.PureComponent {
  render() {
    return (
      <div>
        <h1>Page not found</h1>
      </div>
    )
  }
}

export default NotFound
